/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import PropTypes from 'prop-types';
import { OktaAuth } from '@okta/okta-auth-js';
import { navigate } from 'gatsby';

const authClient = new OktaAuth({
  pkce: true,
  clientId: process.env.GATSBY_OKTA_CLIENTID,
  redirectUri: process.env.GATSBY_OKTA_REDIRECTURL,
  issuer: process.env.GATSBY_OKTA_ISSUER,
  scopes: ['openid', 'email', 'offline_access', 'profile'],
  responseType: 'code',
  storageManager: {
    token: {
      storageTypes: [
        'localStorage',
        'cookie'
      ]
    }
  }
});

const UserContext = React.createContext({
  authClient,
  user: null,
  isAuthenticated: false
});

function UserReducer(state, action) {
  switch (action.type) {
  case 'user':
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user
    };
  case 'redirect':
    authClient.signInWithRedirect();
    return state;
  case 'setToken':
    authClient.tokenManager.setTokens(action.payload.token);
    return state;
  case 'logout':
    localStorage.removeItem('user');
    navigate('/');
    return {
      ...state,
      user: null,
      isAuthenticated: false
    };
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(UserReducer, { authClient, user: null });
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUserContext() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a UserProvider');
  }
  return context;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { useUserContext, UserProvider };
