/* eslint-disable import/no-import-module-exports */
/* eslint-disable arrow-body-style */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';
import WrapRootElement from './src/utils/wrap-root';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (<WrapRootElement>{element}</WrapRootElement>);
};
