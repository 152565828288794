import React from 'react';
import PropType from 'prop-types';
import { UserProvider } from '../context/auth-context';
import { fetchProviderMeta } from './okta-util';

function WrapRootElement({ children }) {
  const providerMeta = async () => {
    const meta = await fetchProviderMeta();
    localStorage.setItem('provider', JSON.stringify(meta?.data));
  };

  React.useEffect(() => {
    providerMeta();
  });

  return (
    <UserProvider>
      {children}
    </UserProvider>
  );
}

WrapRootElement.propTypes = {
  children: PropType.node.isRequired
};

export default WrapRootElement;
