/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
import axios from 'axios';

let timeout = null;

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

const providerMeta = () => JSON.parse(localStorage.getItem('provider') || null);

/** Get Provider Meta - Contains api endpoints  */
const fetchProviderMeta = async () => axios
  .get(`${process.env.GATSBY_OKTA_ISSUER}/.well-known/openid-configuration?client_id=${process.env.GATSBY_OKTA_CLIENTID}`);

const getRefreshEndpoint = () => {
  const meta = providerMeta();

  // Setting Url Params...
  const urlParams = new URLSearchParams();
  urlParams.set('client_id', process.env.GATSBY_OKTA_CLIENTID);
  urlParams.set('redirect_uri', `${window.location.origin}/login/callback/`);
  urlParams.set('response_type', 'code');
  urlParams.set('scope', 'openid email offline_access profile');
  urlParams.set('state', uuidv4());
  urlParams.set('nonce', uuidv4());

  return `${meta.authorization_endpoint}?${urlParams.toString()}`;
};

const getUserDetails = async authClient => {
  try {
    const { tokenManager } = authClient;
    const accessToken = await tokenManager.get('accessToken');
    const idToken = await tokenManager.get('idToken');
    const userInfo = await authClient.token.getUserInfo(accessToken, idToken);
    return userInfo;
  } catch (error) {
    return null;
  }
};

/** AccessToken contains expiresAt run the service before expires */
const startOktaService = async authClient => {
  try {
    const { tokenManager } = authClient;
    const accessToken = await tokenManager.get('accessToken');
    if (!timeout) {
      timeout = setTimeout(async () => {
        await tokenManager.renew('accessToken');
        await tokenManager.renew('idToken');
        await tokenManager.renew('refreshToken');
        timeout = null;
        startOktaService(authClient);
      }, (new Date(accessToken.expiresAt * 1000) - new Date()) - 300000); // Refresh token 5 mins before.
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Unable to refresh tokens');
  }
};

const checkForActiveSession = async authClient => {
  const accessToken = await authClient.tokenManager.get('accessToken');
  const currentTime = new Date();
  const expiresAt = new Date(accessToken.expiresAt * 1000);
  return currentTime < expiresAt;
};

const closeSession = async authClient => {
  clearTimeout(timeout);
  authClient.tokenManager.clear();
  localStorage.clear();
};

export {
  fetchProviderMeta,
  getRefreshEndpoint,
  getUserDetails,
  startOktaService,
  checkForActiveSession,
  closeSession
};
